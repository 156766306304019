/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h3: "h3",
    pre: "pre",
    code: "code",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Quill allows several ways to customize it to suit your needs. This section is dedicated to tweaking existing functionality. See the ", React.createElement(_components.a, {
    href: "/docs/modules/"
  }, "Modules"), " section for adding new functionality and the ", React.createElement(_components.a, {
    href: "/docs/themes/"
  }, "Themes"), " section for styling."), "\n", React.createElement(_components.h3, null, "Container"), "\n", React.createElement(_components.p, null, "Quill requires a container where the editor will be appended. You can pass in either a CSS selector or a DOM object."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const editor = new Quill('#editor');  // First matching element will be used\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const container = document.getElementById('editor');\nconst editor = new Quill(container);\n")), "\n", React.createElement(_components.h3, null, "Options"), "\n", React.createElement(_components.p, null, "To configure Quill, pass in an options object:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const options = {\n  debug: 'info',\n  modules: {\n    toolbar: '#toolbar'\n  },\n  placeholder: 'Compose an epic...',\n  readOnly: true,\n  theme: 'snow'\n};\nconst editor = new Quill('#editor', options);\n")), "\n", React.createElement(_components.p, null, "The following keys are recognized:"), "\n", React.createElement(_components.h4, null, "bounds"), "\n", React.createElement(_components.p, null, "Default: ", React.createElement(_components.code, null, "document.body")), "\n", React.createElement(_components.p, null, "DOM Element or a CSS selector for a DOM Element, within which the editor's ui elements (i.e. tooltips, etc.) should be confined. Currently, it only considers left and right boundaries."), "\n", React.createElement(_components.h4, null, "debug"), "\n", React.createElement(_components.p, null, "Default: ", React.createElement(_components.code, null, "warn")), "\n", React.createElement(_components.p, null, "Shortcut for ", React.createElement(_components.a, {
    href: "/docs/api/#debug"
  }, "debug"), ". Note ", React.createElement(_components.code, null, "debug"), " is a static method and will affect other instances of Quill editors on the page. Only warning and error messages are enabled by default."), "\n", React.createElement(_components.h4, null, "formats"), "\n", React.createElement(_components.p, null, "Default: All formats"), "\n", React.createElement(_components.p, null, "Whitelist of formats to allow in the editor. See ", React.createElement(_components.a, {
    href: "/docs/formats/"
  }, "Formats"), " for a complete list."), "\n", React.createElement(_components.h4, null, "modules"), "\n", React.createElement(_components.p, null, "Collection of modules to include and respective options. See ", React.createElement(_components.a, {
    href: "/docs/modules/"
  }, "Modules"), " for more information."), "\n", React.createElement(_components.h4, null, "placeholder"), "\n", React.createElement(_components.p, null, "Default: None"), "\n", React.createElement(_components.p, null, "Placeholder text to show when editor is empty."), "\n", React.createElement(_components.h4, null, "readOnly"), "\n", React.createElement(_components.p, null, "Default: ", React.createElement(_components.code, null, "false")), "\n", React.createElement(_components.p, null, "Whether to instantiate the editor to read-only mode."), "\n", React.createElement(_components.h4, null, "theme"), "\n", React.createElement(_components.p, null, "Name of theme to use. The builtin options are \"bubble\" or \"snow\". An invalid or falsy value will load a default minimal theme. Note the theme's specific stylesheet still needs to be included manually. See ", React.createElement(_components.a, {
    href: "/docs/themes/"
  }, "Themes"), " for more information."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
